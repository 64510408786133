import Link from "next/link";
import React, { useState } from "react";
import { contact_no } from "../../constant";
import { dial, openSupportWhatsAppWeb } from "../../helper";
import PopupDialog from "../Common/PopupDialog";

const Demo5About2 = () => {
  const [isDialDialogOpen, setIsDialDialogOpen] = useState(false);
  const handleDialDialogOpen = () => {
    setIsDialDialogOpen(true);
  };
  const handleCloseDialDialog = () => {
    setIsDialDialogOpen(false);
  };

  const dialOptions = [
    { label: "Dial", action: dial },
    { label: "Whatsapp", action: openSupportWhatsAppWeb },
  ];

  return (
    <section id="about-2" className="about-section division">
      <div className="container">
        <div className="abox-2-holder">
          <div className="row">
            {/* ABOUT BOX #1 */}
            <div className="col-md-12 col-lg-4">
              <div className="abox-2">
                {/* Title */}
                <h5 className="h5-md steelblue-color">Opening Hours</h5>
                {/* Table */}
                <table className="table">
                  <tbody>
                    <tr>
                      <td>Mon – Sun</td>
                      <td> - </td>
                      <td className="text-right">9:00 AM - 8:00 PM</td>
                    </tr>
                    {/* <tr>
                                            <td>Thursday</td>
                                            <td> - </td>
                                            <td className="text-right">
                                                9:00 AM - 8:00 PM
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Friday</td>
                                            <td> - </td>
                                            <td className="text-right">
                                                9:00 AM - 8:00 PM
                                            </td>
                                        </tr>
                                        <tr className="last-tr">
                                            <td>Sun - Sun</td>
                                            <td>-</td>
                                            <td className="text-right">
                                            9:00 AM - 8:00 PM
                                            </td>
                                        </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
            {/* ABOUT BOX #2 */}
            <div className="col-md-6 col-lg-4">
              <div className="abox-2">
                <h5 className="h5-md steelblue-color">Doctors Timetable</h5>
                {/* Text */}
                <p>
                  {/* An magnis nulla dolor at sapien augue iaculis purus tempor
                  magna ipsum vitae and purus primis ipsum magna ipsum at tempus
                  feugiat dolor impedit felis magna ipsum primis cubilia laoreet
                  augue suscipit lectus mauris */}
                </p>
                {/* Button */}
                <Link legacyBehavior href="/timetable">
                  <a
                    className="btn btn-sm btn-blue blue-hover mt-25"
                    style={{ backgroundColor: "#3b5f1d" }}
                  >
                    View Timetable
                  </a>
                </Link>
              </div>
            </div>
            {/* ABOUT BOX #3 */}
            <div className="col-md-6 col-lg-4">
              <div className="abox-2">
                {/* Title */}
                <h5 className="h5-md steelblue-color">Get in Touch</h5>
                {/* Text */}
                <h4 className="h4-sm emergency-call blue-color">
                  <i className="fas fa-phone" />
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      handleDialDialogOpen();
                    }}
                    onMouseOver={(e) => {
                      e.target.style.color = "#3b5f1d";
                    }} // Change color on hover
                    onMouseOut={(e) => {
                      e.target.style.color = "#8e8333";
                    }}
                  >
                    {contact_no}
                  </a>
                </h4>
                <p className="mt-20">
                  Your health is our priority at Iatric Healthcare. Whether you
                  have questions, concerns, or need to schedule an appointment,
                  we&apos;re here to assist you every step of the way. Committed
                  to your satisfaction, we eagerly anticipate partnering with
                  you on your healthcare journey.
                </p>
              </div>
            </div>
          </div>
          {/* End row */}
          <PopupDialog
            isOpen={isDialDialogOpen}
            onClose={handleCloseDialDialog}
            options={dialOptions}
          />
        </div>
      </div>
      {/* End container */}
    </section>
  );
};

export default Demo5About2;
